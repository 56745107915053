import { Selector } from "@grapecity/wijmo.grid.selector";
import constants from "@/constants.js";
import { UndoStack } from "@grapecity/wijmo.undo";
import * as wjGridFilter from "@grapecity/wijmo.grid.filter";
import * as wjcGrid from "@grapecity/wijmo.grid";
import * as wjcCore from "@grapecity/wijmo";
import "@grapecity/wijmo.vue2.grid.grouppanel";
import "@grapecity/wijmo.vue2.grid";
import "@grapecity/wijmo.vue2.grid.detail";
import "@grapecity/wijmo.vue2.input";
import "@grapecity/wijmo.vue2.grid.filter";
import "@grapecity/wijmo.vue2.viewer";
import "@grapecity/wijmo.vue2.grid.search";
import { mapActions } from "vuex";
export default {
  components: {},

  props: {},
  data() {
    return {
      dados: [],
      tab: 0,
      flex: null,
      undoStack: null,
      canUndo: false,
      canRedo: false,
      situac: true,
      total: 0,
      multipleselection: [{ row: null }],
      checked: 0,
      indeterminate: false,
      checkedAll: true,
      keyFlexGrid: 0,
      camposColunas: {
        ce110_cd_prode: null,
        ce110_cd_ref: null,
        ce110_ds_prod: null,
        ce110_cd_situac: null,
        ce110_cd_depto: null,
        ce110_cd_secao: null,
        ce110_cd_colec: null,
        ce110_cd_grp: null,
        ce110_cd_categ: null,
        ce110_cd_marca: null,
        ce110_vl_compra: null,
        ce110_vl_venda: null,
        ce110_cd_uncom: null,
        ce110_cd_origem: null,
        ce110_cd_ncm: null,
        ce110_cd_exncm: null,
      },
      colunas: [
        {
          cabecalho: "SKU",
          larg: 100,
          valor: "ce110_cd_prode",
          align: "left",
          format: "n",
          regex: "number",
        },
        {
          cabecalho: "Referência",
          larg: 100,
          valor: "ce110_cd_ref",
          align: "left",
        },
        {
          cabecalho: "Produto",
          larg: 200,
          valor: "ce110_ds_prod",
        },
        {
          cabecalho: "Situação",
          larg: 80,
          valor: "ce110_cd_situac",
          align: "center",
        },
        {
          cabecalho: "Departamento",
          larg: 130,
          valor: "ce110_cd_depto",
        },
        {
          cabecalho: "Seção",
          larg: 70,
          valor: "ce110_cd_secao",
        },
        {
          cabecalho: "Coleção",
          larg: 75,
          valor: "ce110_cd_colec",
        },
        {
          cabecalho: "Grupo",
          larg: 70,
          valor: "ce110_cd_grp",
        },
        {
          cabecalho: "Categoria",
          larg: 90,
          valor: "ce110_cd_categ",
        },
        {
          cabecalho: "Marca",
          larg: 75,
          valor: "ce110_cd_marca",
        },
        {
          cabecalho: "Vlr. compra",
          larg: 100,
          valor: "ce110_vl_compra",
          align: "right",
        },
        {
          cabecalho: "Vlr. venda",
          larg: 100,
          valor: "ce110_vl_venda",
          align: "right",
        },
        {
          cabecalho: "Unidade",
          larg: 80,
          valor: "ce110_cd_uncom",
        },
        {
          cabecalho: "Origem",
          larg: 75,
          valor: "ce110_cd_origem",
        },

        {
          cabecalho: "NCM",
          larg: 100,
          valor: "ce110_cd_ncm",
        },
        {
          cabecalho: "Ex. NCM",
          larg: 80,
          valor: "ce110_cd_exncm",
        },
      ],
    };
  },
  async mounted() {
    this.colunas.reverse();
    this.vuexSet({ value: "", stateName: "tempoexecucao" });
    this.setMenuKey(this.$route.path);
    if (this.flex) {
      this.columnPicker.itemsSource = this.flex.columns;
      this.columnPicker.checkedMemberPath = "visible";
      this.columnPicker.displayMemberPath = "header";
      document.addEventListener(
        "mousedown",
        (e) => {
          var trg = e.target;
          if (!constants.wjcCore.closestClass(trg, "column-picker-container")) {
            this.hide();
          }
        },
        true,
      );

      this.gridFilter = new wjGridFilter.FlexGridFilter(this.flex);

      const theSearch = constants.wjcCore.Control.getControl(
        this.$refs.search.$el,
      );
      theSearch.grid = this.flex;
    }

    this.undoStack = new UndoStack("#undoable-form", {
      maxActions: 50,
      stateChanged: (s) => {
        this.canUndo = s.canUndo;
        this.canRedo = s.canRedo;
      },
    });
    if (localStorage.getItem("Tema")) {
      this.handleTheme();
    }
    let usuario = await this.$wso2.getUserData();
    //Cria filtro de Select com todas as lojas do usuário
    var lojas = [];
    this.listaLojas.forEach(function(loja) {
      lojas.push({
        value: loja.key,
        text: loja.value,
        type: ["text"],
      });
    });
    this.opcoes.unshift({
      value: "NR_LOJA",
      text: this.$t("MENU.loja"),
      type: ["select"],
      options: lojas,
    });
  },
  methods: {
    popula() {
      this.dados.push(this.camposColunas);
      this.keyFlexGrid++;
    },
    selectionChanged() {
      this.multipleselection = this.flex.selectedRanges;
    },

    initializeGridDetails(flex) {
      this.flexDetails = flex;
    },

    initializedPicker(picker) {
      this.columnPicker = picker;
    },
    onCheckedItemsChanged(s) {
      // muda o layout da caixa de selecao "selecionar todos"
      if (s.checkedItems.length === 0) {
        this.indeterminate = false;
        this.checkAll = false;
        this.estado = false;
      } else {
        this.indeterminate = !(
          s.checkedItems.length === this.flex.columns.length
        );
        this.checkAll = true;
        this.estado = true;
      }
      this.checked = s.checkedItems.length;
      this.total = this.flex.columns.length;
    },

    hide() {
      constants.wjcCore.hidePopup(this.columnPicker.hostElement.parentElement);
    },

    selectAll() {
      this.estado = !this.estado;
      if (this.estado) {
        this.columnPicker.checkedItems = this.columnPicker.collectionView.items;
        this.checkAll = this.estado;
      } else {
        this.columnPicker.checkedItems = [];
        this.checkAll = this.estado;
      }
    },
    initializeGrid(flex) {
      this.flexDetails = flex;
      this.flexDetails.columnFooters.rows.push(new wjcGrid.GroupRow());
      // this.flexDetails.bottomLeftCells.setCellData(0, 0, "S");
      this.flex.formatItem.addHandler((s, e) => {
        // adiciona o simbolo da engrenagem na tabela, assim como a cor
        if (e.panel == s.topLeftCells) {
          wjcCore.addClass(e.cell, "wijimo-engrenagem");
        }
      });
      const ref = this.flex.hostElement.querySelector(".wj-topleft");
      ref.addEventListener("mousedown", (e) => {
        const pickerRef = this.columnPicker.hostElement.parentElement;
        if (constants.wjcCore.hasClass(e.target, "column-picker-icon")) {
          constants.wjcCore.showPopup(pickerRef, ref, false, true, false);
          pickerRef.focus();
          e.preventDefault();
        }
      });
    },
  },
};
