import inclusaoProduto from "@/components/inclusaoProduto";
export default {
  components: { inclusaoProduto },

  props: {},
  data() {
    return {};
  },
  methods: {},
};
